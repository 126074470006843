import React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import * as styles from '../styles/home.module.css'
import Img from 'gatsby-image'


const Home = ({ data }) => {
  console.log(data)

  const banner = data.banner.childImageSharp
  const polyglot = data.polyglot.childImageSharp

  return (
    <Layout>  
      <section className={styles.header}>
        <div>
          <h2>Senior Solutions Architect & Data Engineer </h2>
          <br />

          <h3>Solutions Architect, Data Engineer & Full Stack Developer </h3>
          <h4> Polyglot Enabled!  </h4>
          <br />
          <Img fluid={polyglot.fluid} />

          <p> A Solutions Architect, Data Engineer, and Full-Stack PolyGot Developer, with focus on Business Intelligence, Data Science, Cloud Integrations, Learning Systems, Distributed Microservices, Restful APIs, DeFi Blockchain DApps and Smart Contracts.</p>

          <Link className={styles.btn} to="/projects">
            My Portfolio Projects 
          </Link>

        </div>
        <Img fluid={banner.fluid} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query Banner {
    banner: file(relativePath: {eq: "banner.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    polyglot: file(relativePath: {eq: "polyglot-4.png"}) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }

`

export default Home

